"use client";

import ErrorMessage from "@/components/Error/ErrorMessage";
import * as Sentry from "@sentry/nextjs";
import { NextPageContext } from "next";

const CustomError = ({ message }: { message: string }) => {
  return <ErrorMessage source="server" message={message} />;
};

CustomError.getInitialProps = async (contextData: NextPageContext) => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);
  return { message: "An error occurred" };
};

export default CustomError;
