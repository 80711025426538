import useAnalytics from "@/hooks/useAnalytics";
import useLayoutSpacing from "@/hooks/useLayoutSpacing";
import { MixpanelEventName } from "@/types";
import { Error } from "@phiaco/phia-ui";
import React, { useEffect } from "react";
import Link from "../shared/Link";

interface Props {
  message?: string;
  source: "client" | "server";
}

const ErrorMessage: React.FC<Props> = ({ message, source }) => {
  const { headerHeight } = useLayoutSpacing();
  const { logMixpanelEvent } = useAnalytics();

  useEffect(() => {
    logMixpanelEvent({
      name: MixpanelEventName.ERROR_PAGE_VIEW,
      properties: { message, source },
    });
    // Disabling to log only once on load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ minHeight: `calc((100vh - ${headerHeight}px) - 280px)` }}
      className="flex flex-col items-center justify-center">
      <Error
        heading={message ?? "An error occurred"}
        subheading={
          <>
            Please try reloading the page or contact{" "}
            <Link
              eventName={MixpanelEventName.SUPPORT_LINK_CLICKED}
              className="text-cn-accent"
              href="mailto:support@phia.com">
              support@phia.com
            </Link>
          </>
        }
      />
    </div>
  );
};

export default ErrorMessage;
